import React from "react";
import "./index.css";
import { motion, spring } from "framer-motion";

function App() {
  return (
    <div className="main">
      <div className="app">
        <div className="header">
          <img src="/assets/images/ggconsult512.png" alt="img" />
        </div>
        <div className="blur"></div>
        <motion.div
          initial={{ y: "2rem", opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{
            duration: 3,
            type: spring,
          }}
          className="img_container"
        >
          <img src="/assets/images/Ilustration.png" alt="" />
        </motion.div>
        <motion.div
          initial={{ x: "7rem", opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{
            duration: 3,
            type: spring,
          }}
          className="text_con"
        >
          <h1>Maintenance Mode</h1>
          <p>We are adding new features: it will be operational really soon.</p>
        </motion.div>
      </div>
    </div>
  );
}

export default App;
